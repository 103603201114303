import { IProduct } from "../@types/product"

const products: IProduct[] = [
  {
    "id": 0,
    "productName": "Don't Lose Your Head!",
    "color": "all",
    "description": "Hand made and hand painted.  The \"newest\" classic game on the market! Don't Lose Your Head! and other games played with these hand made Flyers.",
    "price": 34,
    "groupCode": "DLYH",
    "__v": 0
  },
  {
    "id": 1,
    "productName": "Add Flyer",
    "color": "all",
    "description": "Hand made and hand painted - colors will vary from photos slightly.  These are individual Flyers that you can order to add to your \"Don't Lose Your Head!\" Game",
    "price": 15,
    "groupCode": "flyer",
    "__v": 0
  },
  {
    "id": 2,
    "productName": "Head to Head",
    "color": "all",
    "description": "Hand made and hydro dipped - each Foiler is unique. Each game contains 2 Foilers.",
    "price": 34,
    "groupCode": "HtH",
    "__v": 0
  }
]

export default products